import React, { useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAdmin } from "../context/AdminContext";
import { GlobalStateContext } from "../context/GlobalStateContext";

const AdminRoute = ({ component: Component, user, ...rest }) => {
  const { getCities } = useAdmin();
  const GlobalState = useContext(GlobalStateContext);
  const currentUser = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    getCities(GlobalState.setLoading, GlobalState.setCities);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser) {
          const displayName = currentUser.providerData[0].displayName;
          if (displayName === null || displayName === "moderator") {
            return <Component {...props} />;
          }
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default AdminRoute;
