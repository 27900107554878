import React, { createContext, useContext, useState, useCallback } from "react";
import { auth, db, storage } from "../firebase"; // Adjust the import path as necessary
import {
  ref,
  deleteObject,
  listAll,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  deleteField,
  serverTimestamp,
  query,
  orderBy,
  limit,
  startAfter,
  runTransaction,
  increment,
  arrayUnion,
  Timestamp,
  or,
} from "firebase/firestore";
import { toastError, toastSuccess } from "../helpers/toast";
import { sendMail } from "../helpers/email";
import { GlobalStateContext } from "./GlobalStateContext";

const AdminContext = createContext();

export const useAdmin = () => useContext(AdminContext);

export const AdminProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const GlobalState = useContext(GlobalStateContext);

  const createUser = async (dbName, data, setLoading, role) => {
    setLoading(true);
    try {
      const { password, ...dataWithoutPassword } = data;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data?.email,
        password
      );

      await updateProfile(userCredential.user, {
        displayName: role,
      });

      const ref = doc(db, dbName, userCredential.user.uid);
      await setDoc(ref, dataWithoutPassword)
        .then(() => {
          toastSuccess(`${dbName} Created Successfully`);

          setLoading(false);
          sendMail(data.email, "registered", password);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          setLoading(false);
        });
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  const signIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setIsAuthenticated(true);

      if (
        userCredential.user.displayName !== "admin" ||
        userCredential.user.displayName !== "moderator"
      ) {
        try {
          const userData = await getCurrentUserData(userCredential.user.uid);
          setUserProfile({ ...userData, id: userCredential.user.uid });
        } catch (error) {
          console.error("Failed to get user data:", error);
        }
      }
      setCurrentUser(userCredential.user);
      if (userCredential.user.displayName === "moderator") {
        const { assignedRegion } = await getDocument(
          "Moderator",
          userCredential.user.uid
        );
        sessionStorage.setItem(
          "user",
          JSON.stringify({ ...userCredential.user, assignedRegion })
        );
      } else {
        sessionStorage.setItem("user", JSON.stringify(userCredential.user));
      }
      return userCredential.user;
    } catch (error) {
      setError(error.message);
      return null;
    }
  };

  const signOut = async () => {
    try {
      await auth.signOut();
      setIsAuthenticated(false);
      toastSuccess("Successfully signed out");
      sessionStorage.removeItem("user");
      setCurrentUser(null);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
    }
  };

  const getDocument = useCallback(async (collection, uid) => {
    try {
      if (!uid) {
        return null;
      }
      const dbRef = doc(db, collection, uid);
      const docSnap = await getDoc(dbRef);
      if (docSnap.exists()) {
        return docSnap.data();
      }
    } catch (error) {
      toastError(error.message);
      return null;
    }

    return null;
  }, []);

  const getCurrentUserData = useCallback(
    async (uid, role) => {
      try {
        // Check the "Employee" collection
        let userData = await getDocument("Employee", uid);

        if (!userData) {
          // Check the "Users" collection
          userData = await getDocument("new_users", uid);
        }

        if (!userData) {
          // Check the "Users" collection
          userData = await getDocument("Moderator", uid);
        }

        if (userData) {
          setUserProfile(userData);
        } else {
          if (!role === null) toastError("No such document!");
        }
        return userData;
      } catch (error) {
        toastError(error.message);
        return null;
      }
    },
    [getDocument]
  );

  const storeData = async (dbName, data, setLoading) => {
    try {
      setLoading(true);
      data = { ...data, created_at: serverTimestamp() };
      const response = await addDoc(collection(db, dbName), data);
      toastSuccess(`${dbName} Created Successfully`);
      setLoading(false);
      return response.id;
    } catch (error) {
      toastError(error.message);
      console.error(`Error adding ${dbName} : `, error);
      setLoading(false);
    }
  };

  const storeDataAtKey = async (collection, key, data, setLoading) => {
    try {
      setLoading(true);
      const docRef = doc(db, collection, key);
      await setDoc(docRef, data);
      toastSuccess(`${collection} Created Successfully`);
      setLoading(false);
    } catch (error) {
      toastError(error.message);
      setLoading(false);
      console.error(`Error adding ${collection} : `, error);
    }
  };

  const storeObjectAtKey = async (
    collection,
    key,
    subkey,
    data,
    setLoading
  ) => {
    try {
      setLoading(true);
      const docRef = doc(db, collection, key.toLowerCase());
      await setDoc(docRef, { [subkey]: data }, { merge: true });
      toastSuccess(`${collection} Created Successfully`);
      setLoading(false);
    } catch (error) {
      toastError(error.message);
      setLoading(false);
      console.error(`Error adding ${collection} : `, error);
    }
  };

  const addCity = async (city, cities, setCities) => {
    try {
      const cityName = city.trim().toLowerCase();
      const cityRef = doc(db, "Cities", city.trim().toLowerCase());
      await setDoc(cityRef, { facilityCount: increment(1) }, { merge: true });
      if (!Object.keys(cities).includes(cityName))
        setCities({
          ...cities,
          [cityName]: cities[cityName] + 1,
        });
    } catch (error) {
      toastError(error.message);
      console.log("Error while adding the city: ", error.message);
    }
  };

  const getCities = async (setLoading, setCities = null) => {
    setLoading(true);
    try {
      const citiesCollectionRef = collection(db, "Cities");
      const querySnapshot = await getDocs(citiesCollectionRef);
      const cities = {};

      querySnapshot.forEach((doc) => {
        cities[doc.id] = doc.data();
      });
      setLoading(false);
      setCities && setCities(cities);
      return cities;
    } catch (error) {
      setLoading(false);
      toastError(error.message);
      console.error("Error fetching cities: ", error);
    }
  };

  const getFacilityData = async (setFacilityData, setLoading) => {
    try {
      setLoading(true);
      const facilityRef = collection(db, "Facilities");
      const snapshot = await getDocs(facilityRef);
      const facilities = [];
      const cities = [];
      snapshot.forEach((doc) => {
        cities.push(doc.id);
        const cityData = doc.data();
        Object.values(cityData).forEach((facility) => {
          facilities.push(facility);
        });
      });

      setFacilityData(facilities);
      setLoading(false);
      return cities;
    } catch (error) {
      const errorMessage = error.message || "Error fetching facilities";
      toastError(errorMessage);
      setLoading(false);
    }
  };

  const getData = useCallback(async (collectionName, setloading, setData) => {
    setloading(true);
    try {
      const data = [];
      const querySnapshot = await getDocs(collection(db, collectionName));
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setData(data);
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
    }
  }, []);

  const getServerData = async (
    dbName,
    setloading,
    setData,
    page,
    pageSize,
    lastVisible
  ) => {
    setloading(true);
    try {
      const data = [];
      let q = query(collection(db, dbName), orderBy("id"), limit(pageSize));

      if (page !== 1) {
        q = query(
          collection(db, dbName),
          orderBy("id"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      // Get the last visible document
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const updateData = async (docId, dbName, updatedData, setLoading) => {
    try {
      setLoading(true);
      const docRef = doc(db, dbName, docId);

      await runTransaction(db, async (transaction) => {
        const docSnapshot = await transaction.get(docRef);

        if (!docSnapshot.exists()) {
          throw new Error("Document does not exist!");
        }
        const { id, ...dataWithoutId } = updatedData;

        // Perform the update within the transaction
        transaction.update(docRef, dataWithoutId);
      });

      toastSuccess(`${dbName} Updated Successfully`);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteObjectWithKey = async (collection, key, subkey, setloading) => {
    try {
      setloading(true);
      const docRef = doc(db, collection, key.toLowerCase());
      await updateDoc(docRef, {
        [subkey]: deleteField(),
      });
      setloading(false);
    } catch (error) {
      console.log(error);
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const decrementCityCount = async (city) => {
    try {
      const cityRef = doc(db, "Cities", city.trim().toLowerCase());
      await setDoc(cityRef, { facilityCount: increment(-1) }, { merge: true });
    } catch (error) {
      setError(error.message);
    }
  };

  const recordAttendance = async (empId, role, action) => {
    const attendanceRef = doc(db, "EmpAttendance", empId);

    // Get the current date in 'YYYY-MM-DD' format
    const currentDate = new Date().toISOString().split("T")[0];

    if (role === "Employee") {
      // Get the current attendance document
      const docSnap = await getDoc(attendanceRef);

      if (action === "start") {
        // If the start time for the current date already exists, return from the function
        if (
          docSnap.exists() &&
          docSnap.data()[currentDate] &&
          docSnap.data()[currentDate].startTime
        ) {
          toastError("You have already started your attendance for today.");
          return;
        }

        // Record the start time
        await setDoc(
          attendanceRef,
          {
            [currentDate]: {
              startTime: serverTimestamp(),
            },
          },
          { merge: true }
        );

        toastSuccess("Attendance started successfully.");
      } else if (action === "end") {
        // If the end time for the current date already exists, return from the function
        if (
          docSnap.exists() &&
          docSnap.data()[currentDate] &&
          docSnap.data()[currentDate].endTime
        ) {
          toastError("You have already ended your attendance for today.");
          return;
        }

        // Record the end time
        await updateDoc(attendanceRef, {
          [`${currentDate}.endTime`]: serverTimestamp(),
        });
        toastSuccess("Attendance ended successfully.");
      }
    }
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const userData = await getCurrentUserData(uid, user.displayName);
        setUserProfile(userData);
        setCurrentUser(user);
        setLoading(false);
      } else {
        setUserProfile(null);
        setCurrentUser(null);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    if (user) {
      setCurrentUser(user);
    }
  }, []);

  const VerifyAccount = async (docId, dbName, setloading) => {
    try {
      setloading(true);
      const docRef = doc(db, dbName, docId);
      const docSnap = await getDoc(docRef);
      const isVerified = docSnap.data()?.isVerified;
      if (isVerified) {
        toastError("Account is already verified");
      } else {
        await updateDoc(docRef, { isVerified: true });
        toastSuccess("Account Verified Successfully");
      }
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const deleteData = async (docId, dbName, setloading) => {
    try {
      setloading(true);
      const docRef = doc(db, dbName, docId);
      await deleteDoc(docRef);
      toastSuccess("Document deleted successfully");
      setloading(false);
    } catch (error) {
      toastError(error.message);
      setError(error.message);
      setloading(false);
    }
  };

  const deleteUserData = async (userId, collection, setLoading) => {
    try {
      setLoading(true);

      // Initialize Firebase services

      // Delete user from Firebase Authentication

      // const idToken = await auth.currentUser.getIdToken();

      const idToken = JSON.parse(sessionStorage.getItem("user")).stsTokenManager
        .accessToken;

      const API_ENDPOINT = "https://dashboardapi-two.vercel.app/deleteUser"; //TODO: Change this to your API endpoint

      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        toastError("Unauthorized to delete user");
        throw new Error("Failed to delete user");
      } else {
        toastSuccess("User deleted from Auth successfully");
      }

      // Get user's document from Firestore
      const docRef = doc(db, collection, userId);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        throw new Error("User document does not exist");
      }

      // Delete images from Firebase Storage using download URLs
      const storageRef = ref(storage, `${collection}/${userId}`);
      const listResult = await listAll(storageRef);
      const deletePromises = listResult.items.map((itemRef) =>
        deleteObject(itemRef)
      );
      await Promise.all(deletePromises);
      toastSuccess("User images deleted successfully");

      // Use Firestore transaction to delete user's document
      await runTransaction(db, async (transaction) => {
        const docSnapshot = await transaction.get(docRef);
        if (!docSnapshot.exists()) {
          throw new Error("User document does not exist");
        }
        transaction.delete(docRef);
      });

      toastSuccess("User document deleted from Firestore successfully");
    } catch (error) {
      toastError(error.message);
      console.error("Error deleting user data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (collection, userId, file, setLoading) => {
    setLoading(true);
    try {
      const storageRef = ref(storage, `${collection}/${userId}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Progress function (optional)
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          // Error function
          toastError(error.message);
          setLoading(false);
        },
        async () => {
          // Complete function
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const docref = doc(db, "new_users", userId);
          await updateDoc(docref, {
            images: arrayUnion(downloadURL),
          });
          toastSuccess("File uploaded successfully");
          setLoading(false);

          return downloadURL;
        }
      );
    } catch (error) {
      toastError(error.message);
      setLoading(false);
    }
  };

  const value = {
    currentUser,
    error,
    createUser,
    signIn,
    signOut,
    loading,
    isAuthenticated,
    userProfile,
    storeData,
    storeDataAtKey,
    getData,
    addCity,
    getCities,
    getFacilityData,
    updateData,
    deleteData,
    decrementCityCount,
    deleteObjectWithKey,
    recordAttendance,
    getDocument,
    getServerData,
    VerifyAccount,
    storeObjectAtKey,
    deleteUserData,
    handleFileUpload,
  };
  return (
    <AdminContext.Provider value={value}>
      {!loading && children}
    </AdminContext.Provider>
  );
};
