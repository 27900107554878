import React, { useState } from "react";

// create context
export const GlobalStateContext = React.createContext();

export const GlobalStateProvider = ({ children }) => {
  const [cities, setCities] = useState({});
  const [loading, setLoading] = useState(true);

  const value = { cities, setCities, loading, setLoading };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};
